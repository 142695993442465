// src/stores/authStore.js
import { ref } from "vue";

const isLoggedIn = ref(false); // Initialize to `false` by default

export function useAuthStore() {
  // Initialize login status based on localStorage token presence
  const initializeAuth = () => {
    // console.log('init')
    isLoggedIn.value = !!localStorage.getItem("token");
    // console.log("isLoggedIn --> ", isLoggedIn.value);
    // console.log(localStorage.getItem("token"));
  };

  const login = (token) => {
    localStorage.setItem("token", token);
    isLoggedIn.value = true; // Update reactive state
  };

  const logout = () => {
    // console.log("logout");
    localStorage.removeItem("token");
    isLoggedIn.value = false; // Update reactive state
  };

  return {
    isLoggedIn,
    initializeAuth, // Expose this function
    login,
    logout,
  };
}
