<template>
  <v-container>
    <h1>Contact Us</h1>
    <p>Get in touch with us for more information.</p>
  </v-container>
</template>

<script>
export default {
  name: 'ContactComponent',
};
</script>
