<template>
  <v-app>
    <nav class="fixed-nav">
      <v-toolbar color="blue darken-2" dark>
        <v-toolbar-title class="clickable-title" @click="goToHome"
          >BOOKMIND.TECH</v-toolbar-title
        >
        <v-spacer></v-spacer>

        <v-btn text @click="navigateTo('/')">Home</v-btn>
        <!-- <v-btn text @click="navigateTo('/about')">About</v-btn> -->
        <v-btn text @click="navigateTo('/contact')" v-if="false">Contact</v-btn>
        <v-btn text @click="navigateTo('/chat')" v-if="false">Chat</v-btn>
        <v-btn text @click="navigateTo('/books')">Books</v-btn>
        <!-- <v-btn text @click="navigateTo('/profile')">profile</v-btn> -->

        <!-- Check if user is logged in -->
        <template v-if="isUserLoggedIn">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                text
                v-bind="attrs"
                v-on="on"
                @click="navigateTo('/profile')"
              >
                <v-avatar size="30" class="mr-2">
                  <img :src="user.avatarUrl" alt="User Avatar" />
                </v-avatar>
                {{ user.name }}
              </v-btn>
            </template>
          </v-menu>
        </template>

        <template v-else>
          <v-btn text @click="navigateTo('/login')">Log In</v-btn>
        </template>
      </v-toolbar>
    </nav>

    <v-main class="main-content">
      <!-- This is where your routes will be displayed -->
      <router-view />
    </v-main>

    <footer class="footer">
      <v-container fluid>
        <v-row justify="center">
          <v-col class="text-center">
            <p>
              &copy; {{ new Date().getFullYear() }} BookMind.Tech. All rights
              reserved.
            </p>
          </v-col>
        </v-row>
      </v-container>
    </footer>
  </v-app>
</template>

<script>
import { computed } from "vue";
import { authService } from "@/services/authService";
import { useRouter } from "vue-router";
import { useAuthStore } from "@/stores/authStore";

export default {
  data() {
    return {
      // isLoggedIn: false, // Change this based on your authentication logic
      user: {
        name: "", // Set the name based on the logged-in user's information
        avatarUrl: "https://via.placeholder.com/150", // Replace with actual avatar URL
      },
    };
  },
  setup() {
    const router = useRouter();

    // Computed property to check if the user is logged in
    // const isLoggedIn = computed(() => !!authService.getToken());
    const { isLoggedIn } = useAuthStore();
    const isUserLoggedIn = computed(() => isLoggedIn.value);
    // console.log("App isLoggedIn", isLoggedIn.value);

    const handleLogout = () => {
      authService.logout(); // Clear the token
      router.push("/"); // Redirect to login page
    };

    return {
      isUserLoggedIn,
      handleLogout,
    };
  },
  methods: {
    goToHome() {
      this.$router.push("/"); // Navigates to the home page
    },
    navigateTo(path) {
      this.$router.push(path);
    },
    logout() {
      // Perform logout logic here (e.g., API call, clearing user state)
      this.isLoggedIn = false; // Set this to false when the user logs out
      this.user.name = ""; // Clear user name
      this.user.avatarUrl = ""; // Clear avatar URL
      this.navigateTo("/"); // Redirect to home after logout
    },
    // Dummy method to simulate user login for demonstration
    login() {
      // Simulate successful login
      this.isLoggedIn = false;
      this.user.name = "Joe Bookmind"; // Set user name here
      this.user.avatarUrl = "https://via.placeholder.com/150"; // Set avatar URL here
    },
  },
  mounted() {
    // Simulate user logging in for testing purposes
    this.login();
  },
};
</script>

<style>
.fixed-nav {
  position: fixed; /* Fix the navbar at the top */
  top: 0;
  left: 0;
  right: 0;
  background: white; /* Background color to prevent overlay */
  z-index: 10; /* Ensure it stays above other content */
}

.main-content {
  padding-top: 64px; /* Adjust based on your toolbar height */
}

.footer {
  background-color: #f5f5f5;
  padding: 16px 0;
  text-align: center;
}

.clickable-title {
  cursor: pointer;
  color: #ffffff; /* Adjust color as needed */
}
.clickable-title:hover {
  opacity: 0.8;
}
</style>
