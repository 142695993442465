<!-- HomeComponent.vue -->
<template>
  <v-container class="home-container">
    <canvas ref="canvas" class="three-canvas"></canvas>
    <div class="content-overlay">
      <h1>Welcome to BookMind</h1>
      <p>Engage in interactive conversations with your books, powered by advanced AI technology.</p>
      <v-btn color="primary"  v-if="false" @click="exploreMore">Explore More</v-btn>
    </div>
  </v-container>
</template>

<script>
import * as THREE from "three";
import { onMounted, ref, onBeforeUnmount } from "vue";

export default {
  name: "HomeComponent",
  setup() {
    const canvas = ref(null);
    let scene, camera, renderer, bookMesh, animationId;

    onMounted(() => {
      initThree();
      animate();
    });

    onBeforeUnmount(() => {
      cancelAnimationFrame(animationId);
      renderer.dispose();
      window.removeEventListener("resize", onWindowResize);
    });

    const initThree = () => {
      // Create scene
      scene = new THREE.Scene();
      scene.background = new THREE.Color(0x1a1a2e);

      // Create camera
      camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
      camera.position.z = 5;

      // Initialize renderer
      renderer = new THREE.WebGLRenderer({ canvas: canvas.value, antialias: true });
      renderer.setSize(window.innerWidth, window.innerHeight);
      window.addEventListener("resize", onWindowResize);

      // Add lighting
      const ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
      scene.add(ambientLight);
      const pointLight = new THREE.PointLight(0xffffff, 1);
      pointLight.position.set(5, 5, 5);
      scene.add(pointLight);

      // Create book geometry
      const geometry = new THREE.BoxGeometry(1, 1.5, 0.2);
      const material = new THREE.MeshStandardMaterial({ color: 0x0066cc });
      bookMesh = new THREE.Mesh(geometry, material);
      scene.add(bookMesh);
    };

    const animate = () => {
      animationId = requestAnimationFrame(animate);

      // Rotate the book
      bookMesh.rotation.y += 0.01;
      bookMesh.rotation.x += 0.005;

      renderer.render(scene, camera);
    };

    const onWindowResize = () => {
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(window.innerWidth, window.innerHeight);
    };

    const exploreMore = () => {
      alert("Explore more about BookMind!");
    };

    return {
      canvas,
      exploreMore,
    };
  },
};
</script>

<style scoped>
.home-container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}
.three-canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.content-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
}
.content-overlay h1 {
  font-size: 2.5rem;
  margin-bottom: 0.5rem;
}
.content-overlay p {
  font-size: 1.2rem;
  margin-bottom: 1.5rem;
}
</style>
