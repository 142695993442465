// router.js
import { createRouter, createWebHistory } from "vue-router";
import HomeComponent from "./components/HomeComponent.vue";
import AboutComponent from "./components/AboutComponent.vue";
import ContactComponent from "./components/ContactComponent.vue";
import ChatComponent from "./components/ChatComponent.vue";
import BooklistComponent from "./components/BooklistComponent.vue"; // Import the BookList component
import LoginComponent from "./components/LoginComponent.vue";
import ProfileInfoComponent from "./components/ProfileInfoComponent.vue"; // Adjust path as needed
import { authService } from "@/services/authService";

const routes = [
  { path: "/", component: HomeComponent },
  { path: "/about", component: AboutComponent },
  { path: "/contact", component: ContactComponent },
  { path: "/chat", component: ChatComponent },
  {
    path: "/books",
    component: BooklistComponent,
    meta: { requiresAuth: true },
  }, // Add the new route for BookList
  { path: "/login", component: LoginComponent },
  {
    path: "/profile",
    component: ProfileInfoComponent,
    meta: { requiresAuth: true },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth && !authService.getToken()) {
    next("/login");
  } else {
    next();
  }
});

export default router;
