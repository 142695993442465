<template>
  <v-container>
    <h2>Your Book List</h2>

    <!-- Button to open the modal -->
    <v-btn color="primary" @click="showModal = true">Add New Book</v-btn>

    <!-- Modal for adding a new book -->
    <!-- <v-dialog v-model="showModal" max-width="600px">
      <v-card>
        <v-card-title>Add a New Book</v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid">
            <v-row>
              <v-col cols="12" sm="4">
                <v-text-field
                  v-model="newBook.title"
                  label="Title"
                  :rules="[rules.required]"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  v-model="newBook.author"
                  label="Author"
                  :rules="[rules.required]"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  v-model="newBook.genre"
                  label="Genre"
                  :rules="[rules.required]"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  v-model="newBook.description"
                  label="Description"
                  :rules="[rules.required]"
                  required
                ></v-textarea>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="newBook.downloadLink"
                  label="Download Link"
                  :rules="[rules.required]"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="showModal = false">Close</v-btn>
          <v-btn color="primary" @click="addBook">Add Book</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> -->

    <!-- Modal for adding a new book -->
    <v-dialog v-model="showModal" max-width="600px">
      <v-card>
        <v-card-title>Add a New Book</v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid">
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="newBook.bookName"
                  label="Book Name"
                  :rules="[rules.required]"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  v-model="newBook.bookDescription"
                  label="Book Description"
                  :rules="[rules.required]"
                  required
                ></v-textarea>
              </v-col>
              <v-col cols="12">
                <v-file-input
                  v-model="newBook.documents"
                  label="Upload Documents"
                  multiple
                  show-size
                  accept=".pdf,.doc,.docx"
                  prepend-icon="mdi-file-document"
                ></v-file-input>
              </v-col>
              <v-col cols="12">
                <v-file-input
                  v-model="newBook.coverImage"
                  label="Upload Cover Image"
                  show-size
                  accept="image/*"
                  prepend-icon="mdi-image"
                ></v-file-input>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="showModal = false"
            >Close</v-btn
          >
          <!-- <v-btn color="primary" @click="createBook">Add Book</v-btn> -->
          <!-- Add Book Button with Loading Spinner -->
          <v-btn :disabled="loading" color="primary" @click="createBook">
            <v-progress-circular
              v-if="loading"
              indeterminate
              color="white"
              size="20"
              class="mr-2"
            ></v-progress-circular>
            {{ loading ? "Creating..." : "Add Book" }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Display the list of books -->
    <v-row>
      <v-col
        v-for="book in paginatedBooks"
        :key="book.id"
        cols="12"
        sm="6"
        md="4"
      >
        <v-card>
          <v-card-title>{{ book.name }}</v-card-title>
          <v-card-subtitle>{{ book.author }}</v-card-subtitle>
          <v-card-text>
            <p><strong>Genre:</strong> {{ book.genre }}</p>
            <p><strong>Description:</strong> {{ book.description }}</p>
            <v-img
              :src="`https://api.qrserver.com/v1/create-qr-code/?data=${book.downloadLink}`"
              alt="QR Code"
              max-width="200"
            />
          </v-card-text>
          <v-card-actions>
            <v-btn @click="navigateToChat(book.id)" color="primary">Chat</v-btn>
            <v-btn :href="book.downloadLink" color="secondary" target="_blank"
              >Download</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <!-- Pagination Controls -->
    <v-pagination
      v-model="currentPage"
      :length="pageCount"
      :total-visible="5"
      @input="updatePage"
    ></v-pagination>
  </v-container>
</template>

<script>
import axios from "axios";
import { authService } from "@/services/authService";

export default {
  name: "BookListComponent",
  data() {
    return {
      showModal: false,
      valid: false,
      currentPage: 1,
      itemsPerPage: 6,
      loading: false, // Loading state for API call
      newBook: {
        bookName: "",
        bookDescription: "",
        documents: [],
        coverImage: null,
      },
      books: [],
      rules: {
        required: (value) => !!value || "Required.",
      },
    };
  },
  computed: {
    pageCount() {
      return Math.ceil(this.books.length / this.itemsPerPage);
    },
    paginatedBooks() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      return this.books.slice(start, start + this.itemsPerPage);
    },
  },
  methods: {
    async createBook() {
      if (this.$refs.form.validate()) {
        this.loading = true; // Start loading spinner
        // Prepare form data
        const formData = new FormData();
        formData.append("bookName", this.newBook.bookName);
        formData.append("bookDescription", this.newBook.bookDescription);

        // Append multiple documents
        this.newBook.documents.forEach((file) => {
          formData.append("documents", file);
        });

        // Append cover image
        formData.append("coverImage", this.newBook.coverImage);
        const token = authService.getToken();

        try {
          // Make API call to create the book
          await axios.post(
            `${process.env.VUE_APP_API_URL}/create-book`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${token}`, // Add Authorization header
              },
            }
          );
          // console.log("Book created successfully:", response.data);
          this.fetchBooks();

          // Clear the form and close the modal
          this.newBook = {
            bookName: "",
            bookDescription: "",
            documents: [],
            coverImage: null,
          };
          this.showModal = false;
        } catch (error) {
          console.error("Error creating book:", error);
        } finally {
          this.loading = false; // Stop loading spinner
        }
      }
    },
    navigateToChat(bookId) {
      this.$router.push(`/chat?bookId=${bookId}`);
    },
    addBook() {
      if (this.$refs.form.validate()) {
        const bookToAdd = { ...this.newBook };
        this.books.push(bookToAdd);
        this.newBook = {
          title: "",
          author: "",
          genre: "",
          description: "",
          downloadLink: "",
        };
        this.showModal = false;
      }
    },
    updatePage(page) {
      this.currentPage = page;
    },
    async fetchBooks() {
      const token = authService.getToken();
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/book/list`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        this.books = response.data.books;
      } catch (error) {
        console.error("Error fetching books:", error);
      }
    },
  },
  mounted() {
    this.fetchBooks();
  },
};
</script>

<style scoped>
.v-card {
  margin: 10px;
}
</style>
