<template>
  <v-container fluid>
    <v-card>
      <v-card-title>
        <h2 style="color: #3f51b5">Chat with Your Book</h2>
      </v-card-title>
      <v-card-subtitle style="color: #666"
        >Ask anything about the book!
      </v-card-subtitle>
      <v-card-text class="chat-card">
        <div class="layout">
          <!-- Sidebar for Book Detail -->
          <div class="sidebar">
            <v-img :src="book.cover_image" class="book-cover" contain></v-img>
            <div class="book-info">
              <div class="book-title-author">
                <h3>{{ book.name }}</h3>
                <p>
                  <strong>{{ book.author }}</strong>
                </p>
              </div>
              <p class="book-description">{{ book.description }}</p>
            </div>
          </div>

          <!-- Chat Window Section -->
          <div class="chat-window">
            <div class="messages" ref="messagesContainer">
              <div
                v-for="msg in messages"
                :key="msg.id"
                class="message"
                :class="{
                  user: msg.user === currentUserId,
                  bot: msg.user !== currentUserId,
                }"
              >
                <div class="message-content">
                  <span v-html="msg.text"></span>
                  <!-- Using v-html to render HTML -->
                </div>
              </div>
            </div>

            <!-- Message Input Panel -->
            <v-row class="input-panel" align="center">
              <v-col>
                <v-textarea
                  v-model="userInput"
                  @keydown.enter="sendMessage"
                  label="Type your message here..."
                  clearable
                  auto-grow
                  rows="1"
                />
              </v-col>
              <v-progress-linear
                color="cyan"
                :indeterminate="isLoading"
              ></v-progress-linear>
              <v-col cols="auto">
                <v-btn
                  @click="sendMessage"
                  color="primary"
                  style="border-radius: 20px"
                  >Send</v-btn
                >
              </v-col>
            </v-row>
          </div>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
// import axios from "axios";
import axios from "axios";
import { authService } from "@/services/authService";

export default {
  data() {
    return {
      bookId: this.$route.query.bookId,
      book: {},
      messages: [],
      userInput: "",
      currentUserId: "User", // Replace with actual user ID
      isLoading: false,
      bearerToken:
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6ImZjZjAzYjA1LTljOWItNDZkNy05NmVlLTc2MGFiZWMxOTNjMyIsImVtYWlsIjoiYXNzZXR0aGF3dXRAZ21haWwuY29tIiwiaWF0IjoxNzMwMDE5Nzc4LCJleHAiOjE3MzAxMDYxNzh9.Dt8saVmksx3bOJqCC6A5Bselq2xlRzdUuYdFAmrvyBQ",
    };
  },
  methods: {
    async fetchBook() {
      const token = authService.getToken();
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API_URL}/book/${this.bookId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.book = response.data.book;
      } catch (error) {
        console.error("Error fetching books:", error);
      }
    },
    async sendMessage() {
      this.isLoading = true;
      if (this.userInput.trim() === "") return;

      this.messages.push({
        id: Date.now(),
        user: this.currentUserId,
        text: `<strong>User:</strong>` + this.userInput,
      });

      const queryMessage = this.userInput;

      this.userInput = "";

      this.$nextTick(() => {
        const container = this.$refs.messagesContainer;
        container.scrollTop = container.scrollHeight;
      });

      try {
        // Make the API call
        const response = await fetch(`${process.env.VUE_APP_API_URL}/chat-xyz`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.bearerToken}`,
          },
          body: JSON.stringify({
            bookId: this.bookId,
            userMessage: queryMessage,
            conversationId: "1234567890",
            continueRequest: false,
          }),
        });

        const data = await response.json();

        // Append bot response
        this.messages.push({
          id: Date.now(),
          user: "Bot",
          text: `<strong>Bot:</strong> ${data.response || "No response"}`,
        });

        this.$nextTick(() => {
          const container = this.$refs.messagesContainer;
          container.scrollTop = container.scrollHeight;
        });
        this.userInput = "";
        this.isLoading = false;
      } catch (error) {
        console.error("Error:", error);
        this.isLoading = false;
        this.messages.push({
          id: Date.now(),
          user: "Bot",
          text: `<strong>Bot:</strong> There was an error fetching the response.`,
        });
      }
    },
  },
  mounted() {
    this.fetchBook();
  },
};
</script>

<style>
.layout {
  display: flex; /* Align sidebar and chat window side by side */
  height: calc(75vh - 200px); /* Full height minus card title and footer */
  position: relative; /* Positioning context for input panel */
}

.sidebar {
  width: 250px; /* Fixed width for sidebar */
  padding: 10px;
  border-right: 1px solid #ccc; /* Separator line */
}

.chat-window {
  flex: 1; /* Take up remaining space */
  display: flex;
  flex-direction: column; /* Column layout for messages and input */
}

.messages {
  overflow-y: auto; /* Enable vertical scrolling */
  padding: 20px; /* More padding for readability */
  background: #f9f9f9;
  border-left: 1px solid #ccc; /* Separator line between chat and sidebar */
  border-radius: 8px; /* Rounded corners */
  max-height: calc(100vh - 250px); /* Adjust height to leave space for input */
  scroll-behavior: smooth; /* Enable smooth scrolling */
}

.input-panel {
  position: fixed; /* Position the input panel absolutely */
  bottom: 0; /* Align at the bottom of the chat window */
  width: calc(100% - 250px); /* Full width minus sidebar */
  background: white; /* Background color */
  padding: 10px; /* Padding for input panel */
  border-top: 1px solid #ccc; /* Separator for input */
}

/* Responsive Styles */
@media (max-width: 1024px) {
  .layout {
    flex-direction: column; /* Stack layout on smaller screens */
    height: auto; /* Allow height to be auto */
  }

  .sidebar {
    width: 100%; /* Full width for sidebar */
    border-right: none; /* Remove border */
    border-bottom: 1px solid #ccc; /* Add border below sidebar */
  }

  .chat-window {
    width: 100%; /* Full width for chat window */
  }

  .input-panel {
    width: 100%; /* Full width for input panel */
  }
}

/* Tablet Portrait Styles */
@media (max-width: 768px) {
  .layout {
    flex-direction: column; /* Stack layout for portrait mode */
    height: auto; /* Allow height to be auto */
  }

  .sidebar {
    display: none; /* Hide sidebar on mobile */
  }

  .chat-window {
    width: 100%; /* Full width for chat window */
  }

  .messages {
    padding: 10px; /* Less padding for mobile */
  }
}

.fixed-nav {
  position: fixed; /* Fix the navbar at the top */
  top: 0;
  left: 0;
  right: 0;
  background: white; /* Background color to prevent overlay */
  z-index: 10; /* Ensure it stays above other content */
}
</style>
