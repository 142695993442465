<template>
  <v-container>
    <v-card>
      <v-card-title>
        <v-avatar size="64" class="mr-3">
          <img :src="user.avatarUrl" alt="User Avatar" />
        </v-avatar>
        <div>
          <h2>{{ user.name }}</h2>
          <p>{{ user.email }}</p>
        </div>
      </v-card-title>

      <v-card-actions>
        <v-btn color="red" @click="handleLogout">Logout</v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { authService } from "@/services/authService";
import { useRouter } from "vue-router";
import { computed } from "vue";

export default {
  name: "ProfileInfoComponent",
  data() {
    return {
      user: {
        name: "Joe Bookmind", // Replace with actual user data
        email: "joe@example.com", // Replace with actual user email
        avatarUrl: "https://via.placeholder.com/150", // Replace with actual avatar URL
      },
    };
  },
  methods: {},
  setup() {
    const router = useRouter();

    const isLoggedIn = computed(() => !!authService.getToken());

    const handleLogout = () => {
      authService.logout();
      router.push("/"); // Use router.push to navigate
    };

    return {
      isLoggedIn,
      handleLogout,
    };
  },
};
</script>

<style scoped>
.v-card {
  max-width: 400px;
  margin: auto;
}
</style>
