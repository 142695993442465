<template>
  <v-container>
    <h1>About Us</h1>
    <p>Learn more about BookMind and its mission.</p>
  </v-container>
</template>

<script>
export default {
  name: 'AboutComponent',
};
</script>
