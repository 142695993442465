<template>
  <v-container>
    <h2>Login</h2>
    <v-card>
      <v-card-title>Login to Your Account</v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="valid">
          <v-text-field
            v-model="username"
            label="Username"
            :rules="[rules.required]"
            required
          ></v-text-field>
          <v-text-field
            v-model="password"
            label="Password"
            :type="showPassword ? 'text' : 'password'"
            :rules="[rules.required]"
            required
          >
            <template v-slot:append>
              <v-btn icon @click="showPassword = !showPassword">
                <v-icon>{{ showPassword ? "mdi-eye" : "mdi-eye-off" }}</v-icon>
              </v-btn>
            </template>
          </v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="resetForm">Reset</v-btn>
        <v-btn color="primary" @click="handleLogin">Login</v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { ref } from "vue";
import { authService } from "@/services/authService";
import { useRouter } from "vue-router";

export default {
  name: "LoginComponent",
  setup() {
    const username = ref("");
    const password = ref("");
    const errorMessage = ref("");
    const router = useRouter();

    const handleLogin = async () => {
      // console.log("--->>>");
      try {
        await authService.login(username.value, password.value);
        router.push("/books"); // Redirect to a protected route after login
      } catch (error) {
        errorMessage.value = "Invalid username or password";
      }
    };
    return {
      username,
      password,
      errorMessage,
      handleLogin,
    };
  },
  data() {
    return {
      valid: false,
      // username: "",
      // password: "",
      showPassword: false,
      rules: {
        required: (value) => !!value || "Required.",
      },
    };
  },
  methods: {
    login() {
      // console.log("login...");
      if (this.$refs.form.validate()) {
        // Perform login logic here (e.g., API call)
        // console.log("Logging in with:", this.username, this.password);
        // Reset form after login attempt
        this.resetForm();
      }
    },
    resetForm() {
      this.username = "";
      this.password = "";
      this.showPassword = false;
      this.$refs.form.resetValidation();
    },
  },
};
</script>

<style scoped>
.v-card {
  margin: 20px auto;
  max-width: 400px;
}
</style>
