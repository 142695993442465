import axios from "axios";
import { useAuthStore } from "@/stores/authStore";

export const authService = {
  async login(email, password) {
    const response = await axios.post(`${process.env.VUE_APP_API_URL}/login`, {
      email,
      password,
    });
    const token = response.data.token;
    if (token) {
      const authStore = useAuthStore();
      authStore.login(token); // Use the login method from the store
    }
    return response;
  },
  logout() {
    const authStore = useAuthStore();
    authStore.logout(); // Use the logout method from the store
  },

  getToken() {
    return localStorage.getItem("token");
  },
};
